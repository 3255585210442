import moment from 'moment'
const getMomentNowDate=(days=0,date=new Date())=> {
    const _date =moment(date).startOf('day');
    if(days>0){
        //console.log(_date.add(days,'d').format('DD/MM/yyyy HH:mm'))
        return _date.add(days,'d').toDate()
    }else{
        //console.log(_date.subtract(days*(-1),'d').format('DD/MM/yyyy HH:mm'))
        return _date.subtract(days*(-1),'d').toDate()
    }
};

const dateToDBFormat=(date)=>moment(date).format('yyyy-MM-DD');

const dbFormatToMoment=(date)=>moment(date,'yyyy-MM-DD HH:mm');

const dbFormatToPicker=(date)=>moment(date,'DD/MM/yyyy').format('MM/DD/yyyy');

const getDurationBetweenDates=(a=moment(),b=moment(),unit='minutes')=>a.diff(b, unit);

const minutesToHoursAndMinutes=(minutes=0)=>{
    if(minutes<=0)return {hours:0,minutes:0}
    const h = Math.floor(minutes/60);
    const m = minutes%60;
    return {hours:h,minutes:m}
}

const getDateDetailFromDB=(date,language='es')=>{ 
    moment.locale(language)
    return moment(date).format('MMM DD').toUpperCase();
};

const getTimeDetailFromDB=(date)=>moment(date).format('HH:mm');

export {
    getMomentNowDate,
    dateToDBFormat,
    getDurationBetweenDates,
    minutesToHoursAndMinutes,
    dbFormatToMoment,
    getDateDetailFromDB,
    getTimeDetailFromDB,
    dbFormatToPicker
}